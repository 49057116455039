import React, { useState } from 'react';

import './index.scss';

const Footer = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const clear = () => {
    setLoading(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(`Email submitted: ${email}`);

    // Prevent the default form submission behavior
    event.preventDefault();

    const formData = new FormData();
    formData.append("entry.2070912240", email); // Replace with the correct entry ID from your Google Form

    // Send data to Google Form using the Fetch API
    fetch("https://docs.google.com/forms/d/e/1FAIpQLSexOwV7DQzxfdNx8whJRAweK0_bcA1wa-X5Fhsp5J_GJwoBEQ/formResponse", {
      method: "POST",
      body: formData,
      mode: 'no-cors'
    })
    .then(() => {
      // Clear the form
      setEmail('');
      setLoading(true);
      setTimeout(clear, 2000);
    })
    .catch(error => {
      console.error('Error submitting the form:', error);
    });
  }

  return (
    <footer className="footer">
      <div className="footer-body">
        <div className="footer-legal">
          All Rights Reserved 2024 Tag!
        </div>
      </div>
      <div className="footer-contact">
        <form id="email-form" onSubmit={handleSubmit}>
          {/* <label htmlFor="email">Stay in touch:</label> */}
          <div className="footer-contact-signup">
            <input
              placeholder="Email address"
              type="email"
              id="email"
              name="email"
              disabled={loading}
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              required
            />
            <button disabled={loading} type="submit">
              {loading ? "Thank you 🫶🏼" : "Stay in touch"}
            </button>
          </div>
        </form>
      </div>
    </footer>
  );
};

export default Footer;
