import React from 'react';

import './index.scss';

const Section = ({ children, backgroundColor = "white", type }) => {
  const height = type === "hero" ? "100vh" : "auto";
  return (
    <div className={`section ${type === "hero" ? 'section-hero' : '' }`} style={{ backgroundColor, height }}>
      <div className="section-content">
        { children }
      </div>
    </div>
  );
};

export default Section;
