import React, { useState, useEffect } from "react";

import './index.scss';

const TypingEffect = ({ textList, typingSpeed = 50, pauseTime = 2000 }) => {
  const [displayedText, setDisplayedText] = useState(""); // Current displayed text being typed
  const [currentIndex, setCurrentIndex] = useState(0);    // Index of the current string in textList
  const [charIndex, setCharIndex] = useState(0);          // Index of the current character in the string
  const [isTyping, setIsTyping] = useState(true);         // Flag to check if typing is active

  useEffect(() => {
    // Typing effect
    if (isTyping && charIndex < textList[currentIndex].length) {
      const timeoutId = setTimeout(() => {
        setDisplayedText((prev) => prev + textList[currentIndex][charIndex]);
        setCharIndex((prev) => prev + 1);
      }, typingSpeed);
      return () => clearTimeout(timeoutId);
    }

    // When typing is complete, pause before starting the next string
    if (charIndex === textList[currentIndex].length) {
      setIsTyping(false);
      const pauseTimeout = setTimeout(() => {
        setIsTyping(true);
        setDisplayedText(""); // Clear text for the next cycle
        setCharIndex(0);
        setCurrentIndex((prev) => (prev + 1) % textList.length); // Cycle to the next string
      }, pauseTime);
      return () => clearTimeout(pauseTimeout);
    }
  }, [charIndex, isTyping, currentIndex, textList, typingSpeed, pauseTime]);

  return (
    <div className="typing-effect">
      <h3>{displayedText}</h3>
    </div>
  );
};

export default TypingEffect;
