import React from 'react';
import './index.scss';

import Section from '../Section';
import Person from '../Person';

function About() {
  return (
    <Section backgroundColor="orange">
      <div className="about">
        <h2>About us</h2>
        <h3>The team</h3>
        <div className="team">
          <Person
            name="Renee Wurth, PHD, RD"
            imgSrc="https://media.licdn.com/dms/image/v2/D4E03AQFy2DSvO0SquQ/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1709661774309?e=1734566400&v=beta&t=-k-p9NXmW9M5U34LLGV-E5YDndxCKWJzKe2_W5rjVvk"
            url="https://www.linkedin.com/in/renee-wurth/"
            title="Co-founder & CEO"
          />
          <Person
            name="Cat De Roure"
            imgSrc="https://media.licdn.com/dms/image/v2/D5603AQGeyr52lrmipA/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1719036177395?e=1734566400&v=beta&t=wsEOIbB-HW0DfmpolmGy0IPUK-L-itbqq7Al3RwDLCc"
            url="https://www.linkedin.com/in/catderoure"
            title="Co-founder & CTO"
          />
        </div>
      </div>
    </Section>
  );
}

export default About;