import React from 'react';
import './index.scss';

import Section from '../Section';

import StudyImage from '../../images/study-img.png';

function Studies() {
  return (
    <Section backgroundColor="white">
      <div className="studies">
        <div className="studies-section">
          <h2>Studies</h2>
          <p>Everything we do is backed in science and research.</p>

          <div className="study-info">
            <h3>Menstrual symptom tracking</h3>
            <span className="badge">October 2024</span>
            <p>We’re conducting a study to track menstrual cycle symptoms and their triggers using <b>Tag!</b>. The insights gained will help us create personalized tools for better cycle and lifestyle management.</p>
          </div>

          <div className="study-info">
            <h3>Menopause symptom tracking</h3>
            <span className="badge">November 2024</span>
            <p>Our menopause study invites participants to track symptoms and triggers using <b>Tag!</b> to uncover key patterns. The findings will support the development of personalized solutions for navigating menopause more effectively.</p>
          </div>
        </div>
        <div className="studies-section">
          <img src={StudyImage} alt="Study" />
        </div>
      </div>
    </Section>
  );
}

export default Studies;