import React from 'react';
import './index.scss';

import Section from '../Section';
import TypingEffect from '../TypingEffect';

const LandingPage = () => {
  return (
    <Section backgroundColor="orange" type="hero">
      <div className="landing-page">
        <h1>Tag!</h1>
        <h2>Your cycle is your super power</h2>
        <TypingEffect
          textList={[
            "Track symptoms and triggers",
            "Discover trends and patterns",
            "Make lifestyle changes",
          ]}
        />
      </div>
    </Section>
  );
}

export default LandingPage;