import React from 'react';

import './index.scss';

const Person = ({ name, title, url, imgSrc }) => {
  return (
    <div className="person">
      <div className="person-thumbnail">
        <img alt={name} src={imgSrc} />
        <h4><a target="blank" href={url}>{name}</a></h4>
        <h5>{title}</h5>
      </div>
    </div>
  )
};

export default Person;
